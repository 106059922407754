import i18next from 'i18next';
import { CreateParagraphs } from '../utils/Utils';
import clsx from 'clsx';
import style from './style/general.module.scss';
import rtl from './style/rtl.module.scss';
import ltr from './style/ltr.module.scss';

export function Section(props: {
  title: string;
  text: string[];
  backgroundImage: string;
  image: string;
  id?: string;
  direction: 'right' | 'left';
}) {
  const isHebrew = i18next.language === 'he';
  return (
    <div
      id={props.id}
      className={`${style.section} ${style[props.direction]} ${
        isHebrew ? rtl.section : ltr.section
      } ${isHebrew ? rtl[props.direction] : ltr[props.direction]}`}
      // style={{
      //   backgroundImage: 'url(' + props.backgroundImage + ')',
      // }}
    >
      <div
        className={`${style.text_container} ${isHebrew ? rtl.text_container : ltr.text_container}`}>
        <div
          className={`${style.title__content} ${
            isHebrew ? rtl.title__content : ltr.title__content
          }`}>
          <hr />
          <h2>{props.title}</h2>
        </div>
        <div className={`${style.text} `}>{CreateParagraphs(props.text)}</div>
      </div>
      <picture>
        <img
          src={props.image}
          className={`${style.image} ${isHebrew ? rtl.image : ltr.image}`}
          alt=''
        />
      </picture>
    </div>
  );
}
