export function CreateParagraphs(text: string[]) {
  return text.map((text, index) => {
    return <p key={index}>{text}</p>;
  });
}

export function hideID(id: string) {
  const value = document.getElementById(id);
  if (value) {
    value.classList.add('hidden');
  }
}

export function showID(id: string) {
  const value = document.getElementById(id);
  if (value) {
    value.classList.remove('hidden');
  }
}

interface ILanguagePaths {
  [language: string]: string;
}

export const languagesPath: ILanguagePaths = {
  he: './style/ltr.module.scss',
  ru: '.style/rtl.module.scss',
  en: '.style/rtl.module.scss',
};