import style from './style/card.module.scss';
import IMAGES from '../image-library/imageLibrary';

export function Card(props: ICard) {
  return (
    <div
      id={props.id}
      key={'button' + props.title}
      className={`${style.card} ${props.class}`}
    >
      {props.isArrow ? (
        <img
          src={IMAGES.arrow}
          key={'outside__image' + props.title}
          className={style.arrow}
          alt=''
        />
      ) : (
        ''
      )}
      <div className={style.card__context} key={props.title}>
        <img key={'img' + props.title} src={props.image} alt='' />
        <p key={'p' + props.title}>{props.title}</p>
      </div>
    </div>
  );
}

export interface ICard {
  title: string;
  image: string;
  id?: string;
  isArrow?: boolean;
  class?: string;
  onClick?: (e: any) => void;
}
