import style from './style/general.module.scss';
import IMAGES from '../image-library/imageLibrary';
import { t } from 'i18next';


export function Notification() {
  return (
    <>
      <div id={style.hystmodal} className={`${style.hide}`}>
        <div id={style.hystmodal__wrap}>
          <div id={style.hystmodal__window} role='dialog' aria-modal='true'>
            <button
              data-hystclose
              id={style.hystmodal__close}
              onClick={() => {
                document.getElementById(`${style.hystmodal}`)?.classList.add(`${style.hide}`);
                console.log(document.getElementById(`${style.hystmodal}`))
              }}>
              <img src={IMAGES.closeButton} />
            </button>
            <div id={style.image}>
              <img src={IMAGES.order} />
            </div>
            <p>{t('start-project.status.notError')}</p>
          </div>
        </div>
      </div>
      <div id={style.hystmodal__shadow}></div>
    </>
  );
}
