import styles from './footer.module.scss';
import { SocialMediaIcons } from '../social-media/SocialMedia';

export function Footer() {
  return (
    <footer id={styles.footer}>
      <div id={styles.footer__content}>
        <div id={styles.cooperating}>
          <p>© 2023 Deus Art</p>
        </div>
        <div>{SocialMediaIcons()}</div>
      </div>
    </footer>
  );
}
