import './style/general.scss';
import rtl from './style/rtl.module.scss';
import ltr from './style/ltr.module.scss';
import home from '../../resources/icons/menuBurger/home.svg';
import aboutUsMenu from '../../resources/icons/menuBurger/aboutUsMenu.svg';
import principlesMenu from '../../resources/icons/menuBurger/principlesMenu.svg';
import technologyMenu from '../../resources/icons/menuBurger/technologyMenu.svg';
import startProjectMenu from '../../resources/icons/menuBurger/startProjectMenu.svg';
import closeButton from '../../resources/icons/langButton/closeButton.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';

export const Header = () => {
  const { i18n } = useTranslation();
  const isHebrew = i18n.language === 'he';
  const [language, setLanguage] = useState(new Cookies().get('i18next') || 'he');

  useEffect(() => {
    document.body.dir = languages.find((lang) => lang.code === language)?.dir || 'ltr';
  }, [language]);

  const languages = [
    { name: 'russian', code: 'ru', dir: 'ltr' },
    { name: 'hebrew', code: 'he', dir: 'rtl' },
    { name: 'english', code: 'en', dir: 'ltr' },
  ];

  function closeMenu() {
    const value = document.querySelector('#header');
    if (value) value.classList.toggle('open');
  }

  function getLanguagesButtons(id: string) {
    return (
      <ul id={`${id}`} style={{ flexDirection: isHebrew ? 'row-reverse' : 'row' }}>
        <li>
          <button
            onClick={() => {
              Array.from(document.getElementsByClassName({ id }.id)).map((element) => {
                return element.classList.remove('hidden');
              });
            }}>
            {language.toUpperCase()}
          </button>
        </li>
        {languages
          .filter((lang) => lang.code !== language)
          .map((lang, key) => {
            return (
              <li key={'list_lang' + key} className={'language__list ' + id + ' hidden'}>
                <button onClick={handleChangeLocale} key={'button_lang' + key}>
                  {lang.code.toUpperCase()}
                </button>
              </li>
            );
          })}
      </ul>
    );
  }

  const handleChangeLocale = (e: any) => {
    const lang = e.target.innerText.toLowerCase();
    Array.from(document.getElementsByClassName('language__list')).map((element) => {
      return element.classList.add('hidden');
    });
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <header id='header'>
      <div
        className='container header__container'
        style={{ flexDirection: isHebrew ? 'row-reverse' : 'row' }}>
        <div
          className='logo__container'
          style={{ flexDirection: isHebrew ? 'row-reverse' : 'row' }}>
          <a href='/' className='Logo'>
            <img
              className='logo__img'
              src={require('../../resources/icons/logo.svg').default}
              alt='Логотип'
            />
          </a>
          {getLanguagesButtons('lang__mobile')}
        </div>
        <button
          className='header__burger-btn'
          id='burger'
          onClick={(e) => {
            const value = document.querySelector('#header');
            if (value) value.classList.toggle('open');
          }}>
          <span></span>
          <span></span>
          <span></span>
        </button>

        <nav className='menu'>
          <ul className={`menu__list ${isHebrew ? rtl.menu__list : ltr.menu__list}`}>
            <li className={`menu__item ${isHebrew ? rtl.menu__item : ltr.menu__item}`}>
              <a
                className={`menu__link ${isHebrew ? rtl.menu__link : ltr.menu__link}`}
                href='#start__href'
                id='Start'
                onClick={(e) => {
                  closeMenu();
                }}>
                <img src={startProjectMenu} alt='' />
                {i18n.t('header.start-project')}
              </a>
              <hr />
            </li>
            <li className={`menu__item ${isHebrew ? rtl.menu__item : ltr.menu__item}`}>
              <a
                className={`menu__link ${isHebrew ? rtl.menu__link : ltr.menu__link}`}
                href='#technology'
                onClick={(e) => {
                  closeMenu();
                }}>
                <img src={technologyMenu} alt='' />
                {i18n.t('header.technology')}
              </a>
              <hr />
            </li>
            <li className={`menu__item ${isHebrew ? rtl.menu__item : ltr.menu__item}`}>
              <a
                className={`menu__link ${isHebrew ? rtl.menu__link : ltr.menu__link}`}
                href='#principles'
                onClick={(e) => {
                  closeMenu();
                }}>
                <img src={principlesMenu} alt='' />
                {i18n.t('header.principles')}
              </a>
              <hr />
            </li>
            <li className={`menu__item ${isHebrew ? rtl.menu__item : ltr.menu__item}`}>
              <a
                className={`menu__link ${isHebrew ? rtl.menu__link : ltr.menu__link}`}
                href='#about_us'
                onClick={(e) => {
                  closeMenu();
                }}>
                <img src={aboutUsMenu} alt='' />
                {i18n.t('header.about-us')}
              </a>
              <hr />
            </li>
            {getLanguagesButtons('lang__desktop')}
            <li className={`menu__item ${isHebrew ? rtl.menu__item : ltr.menu__item}`}>
              <hr />
              <a
                className='menu__link'
                href='#'
                id='Home'
                onClick={(e) => {
                  closeMenu();
                }}>
                <img src={home} alt='' />
                {i18n.t('header.main-page')}
              </a>
              <hr />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
