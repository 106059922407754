const IMAGES = {
  // Header menu-burger

  logo: require('../../resources/icons/logo.svg').default,
  home: require('../../resources/icons/menuBurger/home.svg').default,
  startProjectMenu:
    require('../../resources/icons/menuBurger/startProjectMenu.svg').default,
  technologyMenu: require('../../resources/icons/menuBurger/technologyMenu.svg')
    .default,
  principlesMenu: require('../../resources/icons/menuBurger/principlesMenu.svg')
    .default,
  aboutUsMenu: require('../../resources/icons/menuBurger/aboutUsMenu.svg')
    .default,

  //Background main_page

  sectionOneBackground: require('../../resources/icons/section/about.svg')
    .default,
  sectionTwoBackground: require('../../resources/icons/section/principles.svg')
    .default,
  sectionThreeBackground:
    require('../../resources/icons/section/technology.svg').default,

  //Footer

  facebookWhite: require('../../resources/icons/facebook.svg').default,
  whatsAppWhite: require('../../resources/icons/whatsapp.svg').default,
  telegramWhite: require('../../resources/icons/telegram.svg').default,
  instagramWhite: require('../../resources/icons/instagram.svg').default,

  //Start Project

  businessCard:
    require('../../resources/icons/printableAdvertisement/businessCard.svg')
      .default,
  audit: require('../../resources/icons/analytics/siteAudit.svg').default,
  advertising: require('../../resources/icons/startProject/advertising.svg')
    .default,
  webDev: require('../../resources/icons/startProject/webDev.svg').default,
  other: require('../../resources/icons/analytics/other.svg').default,
  arrow: require('../../resources/icons/startProject/arrow.svg').default,
  buttonBack: require('../../resources/icons/buttonBack.svg').default,
  closeButton: require('../../resources/icons/langButton/closeButton.svg')
    .default,

  //__analytics

  adSettings: require('../../resources/icons/analytics/advertisingSettings.svg')
    .default,
  competitiveness:
    require('../../resources/icons/analytics/competitiveness.svg').default,
  seo: require('../../resources/icons/analytics/seo.svg').default,

  //__internetMarketing

  contextAd:
    require('../../resources/icons/internetMarketing/contextAdvertising.svg')
      .default,
  siteContent:
    require('../../resources/icons/internetMarketing/siteContent.svg').default,
  targetAd:
    require('../../resources/icons/internetMarketing/targetedAdvertising.svg')
      .default,

  //__notFoundPage

  backgroundNotFound:
    require('../../resources/icons/notFoundPage/backgroundNotFound.svg')
      .default,
  icon404: require('../../resources/icons/notFoundPage/icon404.svg').default,

  //__printableAdvertisement

  flyer: require('../../resources/icons/printableAdvertisement/flyer.svg')
    .default,
  poster: require('../../resources/icons/printableAdvertisement/poster.svg')
    .default,

  //__websiteDev

  corporate: require('../../resources/icons/websiteDev/corporate.svg').default,
  landingPage: require('../../resources/icons/websiteDev/landingPage.svg')
    .default,
  onlineStore: require('../../resources/icons/websiteDev/onlineStore.svg')
    .default,
  personalWeb: require('../../resources/icons/websiteDev/personalWeb.svg')
    .default,

  //__timeProject

  month: require('../../resources/icons/timeProject/month.svg').default,
  week: require('../../resources/icons/timeProject/week.svg').default,
  now: require('../../resources/icons/timeProject/now.svg').default,

  //__yourContact form

  email: require('../../resources/icons/yourContact/email.svg').default,
  user: require('../../resources/icons/yourContact/nameUser.svg').default,
  number: require('../../resources/icons/yourContact/telephoneNumber.svg')
    .default,

  //__form

  error: require('../../resources/icons/warningIcon.svg').default,
  order: require('../../resources/icons/orderСompleted.svg').default,
};
export default IMAGES;
