import IMAGES from '../image-library/imageLibrary';
import style from './socialMedia.module.scss';

export function SocialMediaIcons() {
  return (
    <ul id={style.container}>
      <li>
        <a href='https://wa.me/+972544262133'>
          <img alt='' src={IMAGES.whatsAppWhite} />
        </a>
      </li>
      <li>
        <a href='https://t.me/deus_art_studio'>
          <img alt='' src={IMAGES.telegramWhite} />
        </a>
      </li>
      <li>
        <a href='https://instagram.com/deus.art.studio?igshid=MzRlODBiNWFlZA=='>
          <img alt='' src={IMAGES.instagramWhite} />
        </a>
      </li>
      <li>
        <a href='https://www.facebook.com/profile.php?id=100082444160998'>
          <img alt='' src={IMAGES.facebookWhite} />
        </a>
      </li>
    </ul>
  );
}
