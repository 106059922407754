import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/main-page/MainPage';
import { NotFound } from './pages/not-found/NotFound';

import './i18n';
import ReactGA from 'react-ga4';
import { Loader } from './components/loader/Loader';

ReactGA.initialize('G-M6DB3Q1WC7');
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path='/' element={<MainPage />}>
          <Route path='ru' element={<MainPage />} />
          <Route path='en' element={<MainPage />} />
          <Route path='he' element={<MainPage />} />
        </Route>
        <Route path='*' element={<MainPage />} />
      </Routes>
    </Suspense>
  </BrowserRouter>,
);
