import i18next, { t } from 'i18next';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Notification } from '../notification/Notification';

import styles from './style/general.module.scss';
import ltr from './style/ltr.module.scss';
import rtl from './style/rtl.module.scss';
import IMAGES from '../image-library/imageLibrary';
import { Card } from '../card/Card';
import notification from '../notification/style/general.module.scss'

export type IFormInputs = {
  time: string;
  work: number;
  username: string;
  tel: string;
  email: string;
  description: string;
};

export function StartProject() {
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState(t('start-project.title-names.1'));
  const [groupOfWork, setGroupOfWork] = useState('');
  const [isHebrew, setIsHebrew] = useState(i18next.languages[0] === 'he' ? true : false);

  useEffect(() => {
    setIsHebrew(i18next.languages[0] === 'he' ? true : false);
    switch (step) {
      case 1:
        setTitle(t('start-project.title-names.1') as string);
        break;
      case 3:
        setTitle(t('start-project.title-names.3') as string);
        break;
      case 4:
        setTitle(t('start-project.title-names.4') as string);
        break;
      case 5:
        setStep(1);
        document.getElementById(`${notification.hystmodal}`)?.classList.remove(`${notification.hide}`);
        break;
    }
  }, [step, i18next.languages[0]]);

  function increaseStep() {
    setStep(step + 1);
  }

  function decreaseStep() {
    setStep(step - 1);
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormInputs>({
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    fetch('/api/orders', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      console.log(response.status);
      switch (response.status) {
        //work and client created successfully
        case 201:
          // change_name_of_title(props.status.notErrorTitle);
          // setIsError(false);
          // hideClass('form');
          // showID('order__status');
          break;
        //work created but client already exists
        case 200:
          // change_name_of_title(props.status.notErrorTitle);
          // setIsError(false);
          // hideClass('form');
          // showID('order__status');
          break;
        //response is invalid
        case 400:
          // change_name_of_title(props.status.ErrorTitle);
          // setIsError(true);
          // hideClass('form');
          // showID('order__status');
          break;
        default:
          break;
      }
    });
    // .then((data) => {
    //   console.log('Success:', data);
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // });
  };

  return (
    <div id={styles.start__project}>
      <Notification />
      <div
        id={styles.start__project__container}
        className={`${isHebrew ? rtl.start__project__container : ltr.start__project__container}`}>
        <div
          className={`${styles.start__project__step} ${styles.desktop} ${
            isHebrew ? rtl.start__project__step : ltr.start__project__step
          }`}>
          <div id={styles.step__text}>
            <img
              style={{ display: step > 1 ? 'block' : 'none' }}
              src={IMAGES.buttonBack}
              alt=''
              onClick={() => {
                if (step > 1) decreaseStep();
              }}
            />
            <p className={`${isHebrew ? rtl.start__project__text : ltr.start__project__text}`}>
              {t('start-project.steps', { step: { step }.step })}
            </p>
          </div>
          <div id={styles.progress__bar}>
            <div className={`${styles.progress__bar__inner} ${styles['x' + step]}`}></div>
          </div>
        </div>

        <div
          id={styles.start__project__title_content}
          className={`${
            isHebrew ? rtl.start__project__title_content : ltr.start__project__title_content
          }`}>
          <hr />
          <h2 id='start__project__title'>{title}</h2>
        </div>
      </div>
      <div
        className={`${styles.start__project__step} ${styles.mobile} ${
          isHebrew ? rtl.start__project__step : ltr.start__project__step
        }`}>
        <img
          style={{ display: step > 1 ? 'block' : 'none' }}
          src={IMAGES.buttonBack}
          alt=''
          onClick={() => {
            if (step > 1) decreaseStep();
          }}
        />
        <p className={`${isHebrew ? rtl.start__project__text : ltr.start__project__text}`}>
          {t('start-project.steps', { step: { step }.step })}
        </p>
        <div id={styles.progress__bar}>
          <div className={`${styles.progress__bar__inner} ${styles['x' + step]}`}></div>
        </div>
      </div>
      <div id={styles.start__project__content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* ------------------------------------------------ */}
          {/* start first section */}
          <fieldset
            style={{
              display: step === 1 ? 'flex' : 'none',
            }}>
            <button
              type='button'
              onClick={() => {
                increaseStep();
                setTitle(t('start-project.containers.0.title').toString());
                setGroupOfWork('prt-adv');
              }}>
              <Card
                title={t('start-project.containers.0.title')}
                image={IMAGES.businessCard}
                isArrow={true}
              />
            </button>
            <button
              type='button'
              onClick={() => {
                increaseStep();
                setTitle(t('start-project.containers.3.title').toString());
                setGroupOfWork('web-dev');
              }}>
              <Card
                title={t('start-project.containers.3.title')}
                image={IMAGES.webDev}
                isArrow={true}
              />
            </button>
            <button
              type='button'
              onClick={() => {
                increaseStep();
                setTitle(t('start-project.containers.2.title').toString());
                setGroupOfWork('adv');
              }}>
              <Card
                title={t('start-project.containers.2.title')}
                image={IMAGES.advertising}
                isArrow={true}
              />
            </button>
          </fieldset>
          {/* end first section
          {/* -------------------------------------------------------------------- */}

          {/* ---------------------------------------------------------------- */}
          {/* start second section */}
          <fieldset
            style={{
              display: step === 2 ? 'flex' : 'none',
              // maxWidth: '800px',
              // flexWrap: 'wrap',
              justifyContent: 'center',
            }}>
            {/* prt-adv section */}
            <fieldset
              style={{
                display: groupOfWork === 'prt-adv' ? 'flex' : 'none',
                // maxWidth: '800px',
                // flexWrap: 'wrap',
                justifyContent: 'center',
              }}>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='11'
                  style={{ opacity: 0 }}
                />
                <Card
                  title={t('start-project.containers.0.children.0')}
                  image={IMAGES.businessCard}
                />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='12'
                  style={{ opacity: 0 }}
                />
                <Card title={t('start-project.containers.0.children.1')} image={IMAGES.poster} />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='13'
                  style={{ opacity: 0 }}
                />
                <Card title={t('start-project.containers.0.children.2')} image={IMAGES.flyer} />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='14'
                  style={{ opacity: 0 }}
                />
                <Card title={t('start-project.containers.0.children.3')} image={IMAGES.other} />
              </label>
            </fieldset>
            {/* end prt-adv section */}

            {/* web-dev section */}
            <fieldset
              style={{
                display: groupOfWork === 'web-dev' ? 'flex' : 'none',
                // maxWidth: '800px',
                // flexWrap: 'wrap',
                justifyContent: 'center',
              }}>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='31'
                  style={{ opacity: 0 }}
                />
                <Card
                  title={t('start-project.containers.3.children.0')}
                  image={IMAGES.landingPage}
                />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='32'
                  style={{ opacity: 0 }}
                />
                <Card
                  title={t('start-project.containers.3.children.1')}
                  image={IMAGES.personalWeb}
                />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='33'
                  style={{ opacity: 0 }}
                />
                <Card title={t('start-project.containers.3.children.2')} image={IMAGES.corporate} />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='34'
                  style={{ opacity: 0 }}
                />
                <Card
                  title={t('start-project.containers.3.children.3')}
                  image={IMAGES.onlineStore}
                />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='36'
                  style={{ opacity: 0 }}
                />
                <Card title={t('start-project.containers.3.children.4')} image={IMAGES.other} />
              </label>
            </fieldset>
            {/* end web-dev section */}

            {/* adv section */}
            <fieldset
              style={{
                display: groupOfWork === 'adv' ? 'flex' : 'none',
                // maxWidth: '800px',
                // flexWrap: 'wrap',
                justifyContent: 'center',
              }}>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='21'
                  style={{ opacity: 0 }}
                />
                <Card title={t('start-project.containers.2.children.0')} image={IMAGES.contextAd} />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='22'
                  style={{ opacity: 0 }}
                />
                <Card title={t('start-project.containers.2.children.1')} image={IMAGES.targetAd} />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='23'
                  style={{ opacity: 0 }}
                />
                <Card
                  title={t('start-project.containers.2.children.2')}
                  image={IMAGES.siteContent}
                />
              </label>
              <label>
                <input
                  onClick={() => increaseStep()}
                  type='radio'
                  {...register('work')}
                  value='24'
                  style={{ opacity: 0 }}
                />
                <Card title={t('start-project.containers.2.children.3')} image={IMAGES.other} />
              </label>
            </fieldset>
            {/* end adv section */}
          </fieldset>
          {/* end second section */}
          {/* ---------------------------------------------------------------- */}

          {/* ---------------------------------------------------------------- */}
          {/* start third section  */}
          <fieldset
            style={{
              display: step === 3 ? 'flex' : 'none',
              // maxWidth: '800px',
              // flexWrap: 'wrap',
              justifyContent: 'center',
            }}>
            <label>
              <input
                onClick={() => increaseStep()}
                type='radio'
                {...register('time')}
                value='0'
                style={{ opacity: 0 }}
              />
              <Card title={t('start-project.time-buttons.0')} image={IMAGES.now} />
            </label>
            <label>
              <input
                onClick={() => increaseStep()}
                type='radio'
                {...register('time')}
                value='1'
                style={{ opacity: 0 }}
              />
              <Card title={t('start-project.time-buttons.1')} image={IMAGES.week} />
            </label>
            <label>
              <input
                onClick={() => increaseStep()}
                type='radio'
                {...register('time')}
                value='2'
                style={{ opacity: 0 }}
              />
              <Card title={t('start-project.time-buttons.2')} image={IMAGES.month} />
            </label>
          </fieldset>
          {/* end third section */}
          {/* ---------------------------------------------------------------- */}

          {/* ---------------------------------------------------------------- */}
          {/* start fourth section */}
          <div
            id={styles.contact_form}
            style={{
              display: step === 4 ? 'flex' : 'none',
            }}>
            <div
              id={styles.contact_form_content}
              className={`${isHebrew ? rtl.contact_form_content : ltr.contact_form_content}`}>
              <div
                className={`${styles.contact_form_block} ${
                  isHebrew ? rtl.contact_form_block : ltr.contact_form_block
                }`}>
                <label>
                  <p className={styles.name_input}>{t('start-project.form.0.label')}</p>
                  <input
                    placeholder={t('start-project.form.0.mask').toString()}
                    {...register('username', {
                      required: {
                        value: true,
                        message: t('start-project.inputErrors.required'),
                      },
                      minLength: {
                        value: 5,
                        message: t('start-project.inputErrors.minLength', {
                          num: 5,
                        }),
                      },
                      maxLength: {
                        value: 35,
                        message: t('start-project.inputErrors.maxLength', {
                          num: 35,
                        }),
                      },
                      pattern: {
                        value: /\D+$/,
                        message: t('start-project.inputErrors.invalidUsername'),
                      },
                    })}
                  />
                </label>
                <div id={styles.contact_form_name_error}>
                  {errors.username && (
                    <p className={styles.error}>
                      <img src={IMAGES.error} alt='' />
                      {errors.username.message || 'Error!'}
                    </p>
                  )}
                </div>
              </div>
              <div
                className={`${styles.contact_form_block} ${
                  isHebrew ? rtl.contact_form_block : ltr.contact_form_block
                }`}>
                <label>
                  <p className={styles.name_input}>{t('start-project.form.1.label')}</p>
                  <input
                    placeholder={t('start-project.form.1.mask').toString()}
                    {...register('tel', {
                      required: {
                        value: true,
                        message: t('start-project.inputErrors.required'),
                      },
                      minLength: {
                        value: 7,
                        message: t('start-project.inputErrors.minLength', {
                          num: 7,
                        }),
                      },
                      maxLength: {
                        value: 13,
                        message: t('start-project.inputErrors.maxLength', {
                          num: 13,
                        }),
                      },
                      pattern: {
                        value: /^\d+$/,
                        message: t('start-project.inputErrors.invalidTelephoneNumber'),
                      },
                    })}
                  />
                </label>
                <div
                  id={`${styles.contact_form_number_error} ${
                    isHebrew ? rtl.contact_form_number_error : ltr.contact_form_number_error
                  }`}>
                  {errors.tel && (
                    <p className={styles.error}>
                      <img src={IMAGES.error} alt='' />
                      {errors.tel.message || 'Error!'}
                    </p>
                  )}
                </div>
              </div>
              <div
                className={`${styles.contact_form_block} ${
                  isHebrew ? rtl.contact_form_block : ltr.contact_form_block
                }`}>
                <label>
                  <p className={styles.name_input}>{t('start-project.form.2.label')}</p>
                  <input
                    placeholder={t('start-project.form.2.mask').toString()}
                    {...register('email', {
                      required: {
                        value: true,
                        message: t('start-project.inputErrors.required'),
                      },
                      minLength: {
                        value: 7,
                        message: t('start-project.inputErrors.minLength', {
                          num: 7,
                        }),
                      },
                      pattern: {
                        value:
                          /^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-]{1,64}|)|\\"[a-zA-Z0-9.+!% -]{1,64}\\")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-z]{2,}|.[0-9]{1,})$/,
                        message: t('start-project.inputErrors.invalidEmail'),
                      },
                    })}
                  />
                </label>
                <div id={styles.contact_form_email_error}>
                  {errors.email && (
                    <p className={styles.error}>
                      <img src={IMAGES.error} alt='' />
                      {errors.email.message || 'Error!'}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div
              id={styles.contact_form_information_content}
              className={`${styles.contact_form_information_content} ${
                isHebrew
                  ? rtl.contact_form_information_content
                  : ltr.contact_form_information_content
              }`}>
              <label id={styles.contact_form_information}>
                <p id={styles.contact_textarea_name}>{t('start-project.form.3.label')}</p>
                <textarea
                  {...register('description')}
                  placeholder={t('start-project.form.3.mask').toString()}
                />
              </label>
              <input
                value={t('start-project.form.4.label').toString()}
                type='submit'
                disabled={!isValid}
                onClick={() => {
                  increaseStep();
                }}
              />
              <p>{t('start-project.form.5.label')}</p>
            </div>
          </div>
          {/* end fourth section */}
          {/* ---------------------------------------------------------------- */}

          {/* ---------------------------------------------------------------- */}
          {/* start five section */}
          {/* <label style={{ display: step === 5 ? 'flex' : 'none' }}>
            <img src={IMAGES.order} alt='' />
            <p>{t('start-project.status.notError')}</p>
          </label> */}
          <Notification />
          {/* end five section */}
          {/* ---------------------------------------------------------------- */}
        </form>
        <div id={styles.start__project__progress__list}>
          <hr />
          <div
            id={styles.start__project__progress__content}
            className={`${
              isHebrew
                ? rtl.start__project__progress__content
                : ltr.start__project__progress__content
            }`}>
            <h3 id={styles.start__project__progress__list__title}>
              {t('start-project.title-names.0')}
            </h3>
            <ul id={styles.progress__list}>
              <li className={step === 1 ? styles.marked : ''}>
                {t('start-project.title-names.1')}
              </li>
              <li className={step === 2 ? styles.marked : ''}>
                {t('start-project.title-names.2')}
              </li>
              <li className={step === 3 ? styles.marked : ''}>
                {t('start-project.title-names.3')}
              </li>
              <li className={step === 4 ? styles.marked : ''}>
                {t('start-project.title-names.4')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
