import '../../index.scss';
import { Footer } from '../../components/footer/Footer';
import { Header } from '../../components/header/Header';
import { Section } from '../../components/section/Section';
import styles from './styles/mainPage.module.scss';
import startProject from '../../components/start-project/style/general.module.scss';
import ltr from './styles/ltr.module.scss';
import rtl from './styles/rtl.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import IMAGES from '../../components/image-library/imageLibrary';

import heroSectionBackground from '../../resources/img/background.webp';

import sectionOneImage from '../../resources/img/about.webp';
import sectionTwoImage from '../../resources/img/company_principles.webp';
import sectionThreeImage from '../../resources/img/technologies.webp';
import { Card } from '../../components/card/Card';
import i18next from 'i18next';
import { StartProject } from '../../components/start-project/StartProject';

export function MainPage() {
  const { t } = useTranslation();

  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <>
      <div
        id={styles.hero__section__background}
        style={{
          backgroundImage: 'url(' + heroSectionBackground + ')',
        }}>
        <Header />
        <div id={`${styles.hero__section}`}>
          <div id={styles.hero__section__content}>
            <p id={styles.hero__section__tagline}>
              DEUS <span>ART</span>
            </p>
            <h1>{t('hero-section.tagline')}</h1>
            <button
              onClick={() => {
                document.getElementById(`${startProject.start__project}`)?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}>
              {t('hero-section.button')}
            </button>
          </div>
        </div>
      </div>

      <Section
        id='about_us'
        title={t('sections.0.title')}
        text={t('sections.0.text', { returnObjects: true })}
        backgroundImage={IMAGES.sectionOneBackground}
        image={sectionOneImage}
        direction={'left'}
      />
      <Section
        id='principles'
        title={t('sections.1.title')}
        text={t('sections.1.text', { returnObjects: true })}
        backgroundImage={IMAGES.sectionTwoBackground}
        image={sectionTwoImage}
        direction={'right'}
      />
      <Section
        id='technology'
        title={t('sections.2.title')}
        text={t('sections.2.text', { returnObjects: true })}
        backgroundImage={IMAGES.sectionThreeBackground}
        image={sectionThreeImage}
        direction={'left'}
      />

      <StartProject />

      <Footer />
    </>
  );
}
